<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <vuexy-logo />
          <h2 class="brand-text text-primary ml-1">IGO-FIN</h2>
        </b-link>
        <b-card-title class="mb-1">Добро пожаловать на IGO-FIN!</b-card-title>
        <b-card-text class="mb-2">Пожалуйста, войдите в свой аккаунт</b-card-text>
        <!-- form -->
        <validation-observer ref="loginForm" #default="{ invalid }">
          <b-form class="auth-login-form mt-2" @submit.prevent="login">
            <!-- email -->
            <b-form-group label-for="email" label="Email">
              <validation-provider #default="{ errors }" name="Email" rules="required|email">
                <b-form-input
                  id="email"
                  v-model="userEmail"
                  name="login-email"
                  :state="errors.length > 0 ? false : null"
                  autofocus
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- password -->
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="password">Пароль</label>
                <!-- TODO: Сделать восстановление пароля -->
                <!-- <b-link :to="{ name: 'auth-forgot-password-v1' }">
                  <small>Забыли пароль?</small>
                </b-link> -->
              </div>
              <validation-provider #default="{ errors }" name="Password" rules="required">
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="password"
                    v-model="password"
                    :type="passwordFieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false : null"
                    name="login-password"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-button variant="primary" type="submit" block :disabled="invalid"> Войти </b-button>
          </b-form>
        </validation-observer>
      </b-card>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import { getHomeRouteForLoggedInUser } from "@/auth/utils";

export default {
  name: "Login",
  components: {
    ValidationProvider,
    ValidationObserver,
    VuexyLogo,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      userEmail: "",
      password: "",
      status: "",
      // validation rules
      required,
      email,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  methods: {
    async login() {
      const valid = await this.$refs.loginForm.validate();
      if (valid) {
        const formData = new FormData();
        formData.append("username", this.userEmail);
        formData.append("password", this.password);

        try {
          await this.$store.dispatch("user/login", formData);
        } catch ({ response: { status, data } }) {
          let errorMsg = "";
          if (status === 400) errorMsg = "Некорректный логин или пароль";
          else errorMsg = data.detail;

          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Ошибка",
              icon: "AlertTriangleIcon",
              variant: "danger",
              text: errorMsg,
            },
          });
        }

        const rules = this.$store.getters["ability/getRules"];
        if (rules) this.$ability.update(rules);

        try {
          await this.$router.replace(getHomeRouteForLoggedInUser());
        } catch (error) {
          // console.log(error);
        }
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
